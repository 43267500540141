import React from "react";
import styles from "./RideDetails.module.scss";

function RideDetails({ rideDetails }) {
  return (
    <div className={styles.detailsWrap}>
      {rideDetails?.pickup_time == null && <div className={styles.singleHeader}>Thank you for using our Service</div>}
      {rideDetails?.pickup_time != null && (
        <div className={styles.headerNotPickedUp}>
          <div className={styles.textWrap}>
            <div className={styles.header}>Thank you for using our Service</div>
            <div className={styles.subHeader}>Estimated pickup time: </div>
          </div>

          <div className={styles.timeLeft}>
            <div className={styles.time}>{rideDetails.pickup_time}</div>
            <div className={styles.minLabel}>min</div>
          </div>
        </div>
      )}

      <div className={styles.carDetails}>
        <div className={styles.imagesWrap}>
          <img className={styles.driverImage} src={rideDetails?.driver_image} />
          <img className={styles.carImage} src={rideDetails?.car_image} />
        </div>

        <div className={styles.modelAndPlates}>
          <div className={styles.model}>{rideDetails?.car_model}</div>
          <b className={styles.plates}>{rideDetails?.plates}</b>
        </div>
      </div>

      <div className={styles.driverNameAndPhone}>
        <div className={styles.driverName}>
          {rideDetails?.driver_name}
          <div className={styles.ratingAndDriveCount}>
            <img className={styles.driverInfoIcons} src="/ic_rate.svg" />
            <span>{rideDetails?.driver_rate}</span>
            <img className={styles.driverInfoIcons} src="/ic_rides.svg" />
            <span>{rideDetails?.driver_rides_count}</span>
          </div>
        </div>
        <a className={styles.driverPhone} href={`tel:${rideDetails?.driver_phone}`}>
          {rideDetails?.driver_phone}
        </a>
      </div>
      <LocationSegment rideDetails={rideDetails} />
    </div>
  );
}

export default RideDetails;

export const LocationSegment = ({ rideDetails }) => {
  return (
    <div className={styles.locationSegment}>
      <div className={styles.startAddress}>
        <img className={styles.locationImages} src="/pin_from.svg" />
        {rideDetails?.location}
      </div>
      <div className={styles.endAddress}>
        <img className={styles.locationImages} src="/pin_to.svg" />
        {rideDetails?.destination}
      </div>
    </div>
  );
};
