import { useEffect, useState } from "react";
import "./App.css";
import MapWrapper from "./components/MapWrapper";
import NetworkService from "./NetworkService";
import RideDetails from "./components/RideDetails";
import { useSearchParams } from "react-router-dom";
import ErrorPage from "./components/ErrorPage";

function App() {
  const [rideDetails, setRideDetails] = useState();
  const [error, setError] = useState(null);

  const [searchParams] = useSearchParams();
  useEffect(() => {
    const fetchData = () => {
      if (!error) {
        NetworkService.getRideData(searchParams.get("token"))
          .then((res) => {
            setRideDetails(res);
            setError(null);
          })
          .catch((err) => setError(err));
      }
    };
    fetchData();
    const interval = setInterval(fetchData, 8000);

    return () => clearInterval(interval);
  }, [searchParams, error]);

  return (
    <div className="App">
      {!error ? (
        <>
          <MapWrapper rideDetails={rideDetails} />
          <RideDetails rideDetails={rideDetails} />
        </>
      ) : (
        <ErrorPage error={error?.data}>{error?.data?.message}</ErrorPage>
      )}
    </div>
  );
}

export default App;
