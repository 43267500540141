import React, { useEffect, useRef, useState } from "react";
import L from "leaflet";
import { MapContainer, TileLayer, Marker, useMap, Polyline } from "react-leaflet";
import styles from "./MapWrapper.module.scss";

const pinFrom = new L.Icon({
  iconUrl: "/pin_from.png",
  iconSize: [48, 48],
  iconAnchor: [24, 48],
  popupAnchor: [48, 24],
});

const pinTo = new L.Icon({
  iconUrl: "/pin_to.png",
  iconSize: [48, 48],
  iconAnchor: [24, 48],
  popupAnchor: [48, 0],
});

const pinDriver = new L.icon({
  iconUrl: "/pin_car.svg",
  iconSize: [64, 64],
  iconAnchor: [32, 64],
  popupAnchor: [64, 32],
  className: styles.driverMarker,
});

const MapWrapper = ({ rideDetails }) => {
  const [mapServiceData, setMapServiceData] = useState();

  useEffect(() => {
    let mapData = rideDetails?.route_info;
    if (mapData) {
      mapData.shape = remappedCoordinates(mapData.route);
      setMapServiceData(mapData);
    }
  }, [rideDetails]);

  const markerRef = useRef();

  return (
    <div>
      <MapContainer
        maxZoom={16}
        minZoom={10}
        className={styles.mapWrap}
        center={[44.83747771561463, 20.410867113723057]}
        zoom={13}
        bounds={[
          [44.975563009492674, 20.171056176197784],
          [44.65554467734727, 20.746465087431673],
        ]}
        scrollWheelZoom={false}>
        <MapHandler rideDetails={rideDetails} />
        <TileLayer attribution="CAR:GO" url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png" />
        {mapServiceData && <Polyline positions={mapServiceData?.shape} color="#3BD4AE" opacity={0.8} weight={2} />}{" "}
        {rideDetails && (
          <Marker
            ref={markerRef}
            icon={pinDriver}
            position={[rideDetails.current_latitude, rideDetails.current_longitude]}
          />
        )}
        {rideDetails && (
          <Marker
            draggable={false}
            icon={pinTo}
            position={[rideDetails?.destination_latitude, rideDetails?.destination_longitude]}></Marker>
        )}
        {rideDetails && (
          <Marker icon={pinFrom} position={[rideDetails?.location_latitude, rideDetails?.location_longitude]}></Marker>
        )}
      </MapContainer>
    </div>
  );
};

export default MapWrapper;

export const MapHandler = ({ rideDetails }) => {
  const map = useMap();

  map.attributionControl.setPrefix("");

  const [shouldRecenter, setShouldRecenter] = useState(true);

  const prevRideDetails = useRef();

  useEffect(() => {
    const fitPosition =
      rideDetails?.pickup_time == null
        ? L.latLng(rideDetails?.destination_latitude, rideDetails?.destination_longitude)
        : L.latLng(rideDetails?.location_latitude, rideDetails?.location_longitude);

    if (rideDetails && prevRideDetails.current?.pickup_time !== rideDetails?.pickup_time) {
      setShouldRecenter(true);
      reCenterFunc(map, [L.latLng(rideDetails?.current_latitude, rideDetails?.current_longitude), fitPosition]);
    }

    if (rideDetails && shouldRecenter && prevRideDetails.current?.pickup_time !== rideDetails?.pickup_time) {
      reCenterFunc(map, [L.latLng(rideDetails?.current_latitude, rideDetails?.current_longitude), fitPosition]);

      setShouldRecenter(false);
    }
    prevRideDetails.current = rideDetails;
  }, [rideDetails, shouldRecenter]);
};

const remappedCoordinates = (coordinates) =>
  coordinates.map((coord) => {
    const [lat, lng] = coord.split(",").map(parseFloat);
    return [lat, lng];
  });

const reCenterFunc = (map, fitArray) => {
  map.fitBounds(fitArray, {
    padding: [50, 50],
  });
};
