import axios from "axios";

export class NetworkService {
  constructor() {
    this.url = process.env.REACT_APP_API_URL || "https://api.appcargo.com";
    console.log(process.env);
    this.jwtToken = null;
    this._handleErrors = this._handleErrors.bind(this);
    this._handleResponse = this._handleResponse.bind(this);
  }

  _makeRequest(method, path, config = {}, auth = false) {
    let { headers, body, params, ...rest } = config;
    let additionalHeaders = {};
    if ((method === "POST" || method === "PUT") && typeof body === "object" && !(body instanceof FormData)) {
      additionalHeaders["content-type"] = "application/json";
      body = JSON.stringify(body);
    }
    return axios({
      method: method,
      url: `${this.url}/${path}`,
      ...config,
    })
      .then(this._handleResponse)
      .catch(this._handleErrors);
  }

  _handleErrors(error) {
    console.log(error);
    throw error.response;
  }

  async _handleResponse(response) {
    let body = null;
    let contentType = response.headers["content-type"];
    if (contentType && contentType.startsWith("application/json")) {
      body = await response.data;
    } else {
      return response;
    }
    return body;
  }

  getRideData(token) {
    return this._makeRequest("GET", `shared-ride`, {
      params: {
        token: token,
      },
    });
  }
  getMapServiceData(data) {
    return this._makeRequest("POST", `direction/route/get`, {
      data: {
        alternatives: 0,
        waypoints: [],
        lang: "en",
        countryCodes: "RS",
        city: "Belgrade",
        bounds: "",
        ...data,
      },
    });
  }
}

export default new NetworkService();
