import React from "react";
import styles from "./ErrorPage.module.scss";

function ErrorPage() {
  return (
    <>
      <div className={styles.navigationBar}>
        <img src="/stores/cargo-logo-header.svg" />
      </div>
      <div style={{ background: `url("/stores/bcg_right_login.svg")` }} className={styles.errorPageWrap}>
        <div className={styles.errorContent}>
          <div className={styles.header}>Thanks for using CAR:GO services!</div>
          <div className={styles.description}>
            Currently you don't have any service on CAR:GO platform. Our mobile apps provide unique user experience,
            explore and use all our services much more faster, easier and securely.
          </div>
        </div>

        <div className={styles.content}>
          <div className={styles.description}>
            With the CarGo app enjoying the city has never been that easy. Download the app and enter where you want to
            go. A nearby service provider will help you to get wherever you want.
          </div>
          <div className={styles.storesWrap}>
            <a href="https://play.google.com/store/apps/details?id=com.appcargo.user" target="_blank" rel="noreferrer">
              <img alt="store img" className={styles.appStore} src="/stores/btn_google.svg" />
            </a>
            <a href="https://apps.apple.com/us/app/car-go-anywhere/id999970383" target="_blank" rel="noreferrer">
              <img alt="store img" className={styles.appStore} src="/stores/btn_apple.svg" />
            </a>
            <a
              href="https://appgallery.huawei.com/#/app/C103244763?channelId=EURSMKT20210219CG&detailType=0"
              target="_blank"
              rel="noreferrer">
              <img alt="store img" className={styles.appStore} src="/stores/btn_huawei.svg" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default ErrorPage;
